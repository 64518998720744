.favorites {
 
  .favorites-heading {
    border-collapse: collapse;
    cursor: default;
    display: table;
    width: 100%;
    table-layout: fixed;
    margin-bottom: 8px;
    box-sizing: border-box;

    .title,
    .close-icon {
      display: table-cell;
      width: 35px;
      vertical-align: middle;
    }

    .title {
      width: auto;
    }

    .close-icon {
      text-decoration: none;
      width: 48px;
    }
  }
  .favorites-management {
    padding-right: 12px;
    .favorites-manage-button {
      float: right;
      min-width: 180px;
      margin-top: 14px;
      margin-bottom: 10px;
    }

    .favorites-header-folder-select {
      display: inline-block;
      .listbox-button {
        color: #0d8484;
        width: 250px;
        height: 40px;
        border: 1px solid black;
        border-radius: 0px;
        padding: 5px;
      }
    }
  }

  .resultSection {
    margin-top: 20px;
  }
}

.favorites-list {
  .MuiCheckbox-colorSecondary.Mui-checked {
    color: #006272;
  }
  .favorite-select-all-checkbox {
    margin-left: 10px;
  }
}
// manage favorites
.manage-favorites {
  width: 250px;
  background-color: #e1e0e0;
  border: 1px solid grey;

  .manage-favorites-header {
    padding: 10px;
    background-color: white;
    margin-bottom: 2px;

    .header-title {
      display: inline-block;
    }

    .header-close {
      float: right;
    }
  }

  .MuiFilledInput-input {
    padding: 0px;
    padding-top: 27px;
  }
  .MuiCheckbox-colorSecondary.Mui-checked {
    color: #006272;
  }

  .manage-favorites-errors {
    padding-left: 25px;
    color: #dd372f;
    list-style: none;
    background-color: white;
    margin: 0;
    padding-top: 0px;
    padding-bottom: 10px;
  }
  .manage-favorites-folders {
    background-color: white;
    padding: 10px;
    margin-bottom: 2px;
    max-height: 230px;
    overflow-y: auto;
  }

  .manage-favorites-controls {
    background-color: white;
    padding: 10px;

    .controls-done,
    .controls-add-list,
    .controls-new-folder {
      display: block;
      width: 100%;
      border-radius: 5px;
    }

    .controls-characters-remaining,
    .controls-characters-remaining-red {
      margin: 0;
      font-size: small;
      float: right;
      color: #006272;
    }

    .controls-characters-remaining-red {
      color: #dd372f;
    }

    .controls-add-list {
      margin-bottom: 5px;
      background-color: white;
      color: #676767;
      border: 1px solid #676767;
    }

    .controls-done {
      cursor: pointer;
    }

    .controls-done-disabled {
      opacity: 0.5;
      cursor: default;
    }

    // Error class for form
    .MuiFormHelperText-root.Mui-error {
      color: #dd372f;
      margin-left: 0;
    }

    .MuiFormLabel-root.Mui-error {
      color: #dd372f;
    }

    // Text within input field
    .MuiInputLabel-filled.MuiInputLabel-shrink.MuiInputLabel-marginDense {
      transform: translate(12px, 19px) scale(0.75);
    }

    .MuiTextField-root {
      margin-bottom: 5px;
      width: 100%;
      padding: 0;
    }

    .MuiInputBase-root {
      width: 100%;
    }
  }
}
