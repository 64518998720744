.refinement-toggle {
  border: none;
  border-radius: 4px;
  padding: 0.25rem 0.5rem;
  margin: 0 0.25rem 0 0;

  color: #676767;
  background-color: #ffffff;
  cursor: pointer;

  &.active {
    background-color: #aae2d0; /*  primary-light */
    color: #006272; /* primary-dark */
  }
  
  &.last {
    margin-right: 0;
  }
}
.refinement-toggle:focus {
  outline: 2px dotted #424242;
}
