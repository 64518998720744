.cart {
  margin-top: 0.8rem;

  .cart-header {
    padding-left: 9px;

    .cart-button-disabled {
      .MuiSvgIcon-root {
        color: #676767;
      }
    }
  }

  .cart-header__title {
    display: inline-block;
  }

  .cart-body {
    max-height: 250px;
    overflow-y: auto;
  }
}
hr.divider {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  height: 2px;
  background-color: rgba(0, 0, 0, 0.22);
}

.cart-count {
  display: inline-block;
  width: 24px;
  line-height: 21px;
  border-radius: 50%;
  text-align: center;
  font-size: 16px;
  border: 2px solid #676767;
  background-color: #676767;
  color: #ffffff;
  margin-right: 5px;
}

.alert-title-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  @media (min-width: 660px) {
    width: 400px;
  }
  @media (min-width: 800px) {
    width: 600px;
  }
  @media (min-width: 1200px) {
    width: 1000px;
  }
}

.alert-dialog-paper {
  padding-bottom: 20px;
  padding-right: 8px;

  #dialog-title {
    padding-bottom: 0px;
    font-size: 18px;
    color: #006272;
  }

  #alert-dialog-description {
    font-size: 12px;
    color: #676767;
  }

  .alert-dialog-actions {
    padding-left: 70px;
  }

  .keep-edited-content-button {
    color: #424242;
  }
}
