.preview-header {
  width: 100%;

  .preview-header-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .preview-header-text {
    word-wrap: break-word;
    display: inline-block;
    width: 90%;
    padding-left: 5px;
    padding-top: 10px;
  }

  .MuiListItemIcon-root {
    display: inline-block;
  }

  @media screen and (max-width: 720px) {
    .preview-header-text {
      width: 85%;
    }
  }
}
