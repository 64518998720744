.alert-dialog {
  .MuiDialog-paperScrollPaper {
    display: block;
  }

  .MuiDialogActions-root {
    float: right;
  }
}

#alert-dialog-subDescription {
  font-size: 12px;
  color: #676767;
}