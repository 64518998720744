.customAutocomplete {
  width: 90%;
  padding-right: 0;
}

.searchForm {
  width: 100%;
  position: relative;

  // this does nothing in chrome -- keep for IE
  .MuiInputAdornment-positionEnd {
    margin-left: -50px;
  }

  .MuiInputBase-input::-ms-clear {
    display: none;
  }

  .search-icon {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.autocompleteListPopper {
  width: 100%;
  position: absolute;
  z-index: 100;
  left: 0;
  bottom: 0;
  padding: 0;
  display: none;

  &.autocompleteListPopper-open {
    display: block;
  }

  .MuiList-padding {
    padding: 0.5rem 0 0;
  }
}

.autocompleteListPaper {
  position: absolute;
  z-index: 1000;
}
