li.MuiButtonBase-root.MuiListItem-root.customListItem.MuiListItem-gutters.MuiListItem-button {
  width: 100%;
  display: table;
  border-collapse: collapse;
  table-layout: fixed;
  margin-bottom: 8px;
  box-sizing: border-box;
  cursor: default;

  * {
    box-sizing: border-box;
  }

  .MuiListItem-gutters {
    padding: 0px;
  }

  .listItem-left,
  .listItem-center,
  .listItem-right {
    display: table-cell;
    vertical-align: top;
    width: 35px;
  }

  .listItem-left {
    .MuiCheckbox-root {
      display: block;
      height: 24px;

      .MuiIconButton-label {
        display: block;
      }
    }
  }

  .listItem-center {
    width: 100%;
    padding: 0 12px;
  }

  .listItem-right {
    text-align: right;

    .iconWrapper {
      padding-right: 18px;
    }
  }

  .content-language-selector {
    display: inline-block;
    width: 50px;
    margin-right: 10px;
  }

  .checkbox {
    padding: 0 9px 0 18px;
  }

  .listItemContent {
    display: table;
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;

    .itemTitle,
    .content-language-selector {
      display: table-cell;
      vertical-align: top;
    }

    .itemTitle {
      padding-left: 4px;
    }

    .content-language-selector {
      padding-right: 4px;
    }
  }

  .itemTitle {
    font-weight: 700;
    word-wrap: break-word;
  }

  .iconWrapper {
    min-width: 0;
  }
}

.phat {
  li.customListItem {
    .listItemContent {
      width: 100%;
      padding: 0;
      margin: 0;
    }

    .checkbox {
      padding: 0;
    }
  }
}

li.MuiButtonBase-root.MuiListItem-root.customListItem.MuiListItem-gutters.MuiListItem-button.selectedListItem {
  outline: dotted 2px black;
  outline-offset: -2px;
}

#ResultSections {
  .MuiListItem-gutters {
    padding-left: 0px;
    padding-right: 0px;
  }
}
