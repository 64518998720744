.listbox-component {
  margin-bottom: -8px;
  position: relative;

  .listbox-title {
    display: block;
    font-size: 12px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.54);
    width: 100%;
  }

  .listbox-button {
    background: transparent;
    border: none;
    border-radius: 0;
    text-align: left;
    font-size: 1rem;
    cursor: pointer;
    width: 100%;
    min-width: 55px;
    padding: 0;

    &:focus {
      outline: 2px dotted #424242;
    }

    .listbox-button-inner {
      width: 100%;
      display: table;
      table-layout: fixed;
      border-collapse: collapse;

      .listbox-button-label,
      .listbox-button-icon-wrapper {
        display: table-cell;
        vertical-align: middle;
        padding: 2px 0;
      }

      .listbox-button-label {
        width: calc(100% - 32px);
        min-width: 2rem;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .listbox-button-icon-wrapper {
        width: 24px;
        text-align: right;
      }
    }
  }

  .listbox-list {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    list-style: none;
    margin: 0;
    padding: 0;
    box-shadow: 0px 5px 5px -3px #ccc, 0px 8px 10px 1px #ccc, 0px 3px 14px 2px #ccc;
    border-radius: 4px;
    z-index: 20;
    column-gap: 0;
    max-height: 35%;
    overflow-y: scroll;
  }

  .listbox-item {
    padding: 8px 16px;
    height: 41px; // 25px + top/bottom padding
    white-space: nowrap;
    cursor: pointer;
    transition: background-color 0.3s ease-out;
    break-inside: avoid;
  }

  .listbox-item:hover {
    background-color: #ececec;
  }

  .listbox-item:active {
    background-color: #424242;
    color: #efefef;
  }

  .listbox-item-disabled,
  .listbox-item-disabled:hover {
    cursor: not-allowed;
    opacity: 0.5;
  }

  .listbox-item-selected {
    background-color: #d8d8d8;
    cursor: default;
  }

  .listbox-item-disabled.listbox-item-selected {
    opacity: 0.7;
  }

  .listbox-item-highlight,
  .listbox-item-highlight:hover {
    background-color: #aae2d0;
    border: 2px dotted #424242;
  }
}

.list-expanded {
  .listbox-list {
    display: block;
  }
}
