.rendered-hw-content:focus {
  outline: dotted 1px #424242;
  outline-offset: 4px;
}

.rendered-content-multum {
  .HwSlideButtonFrame {
    display: none;
  }

  /* In case Virginia changes her mind later */
  .DrugImagesSlideShow {
    display: none;
  }
  /* .DrugImagesSlideShow {
    display: flex;
    flex-wrap: wrap;
  } */

  .HwSlide,
  .HwSlide.HwDisplayNone {
    box-sizing: border-box;
    display: inline-block;
    vertical-align: top;
    width: 340px;
    padding: 10px 20px;
  }

  .DrugImagesSlideShowImage {
    position: relative;
    width: 300px;
    min-height: 225px;
  }

  .DrugImagesSlideShowImage img {
    width: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .HwSlideImageDescription {
    min-height: 55px;
  }
}
