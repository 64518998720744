#LoadingSpinner {
  padding-top: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //color: #6095C0;

  .loader {
    height: 120px !important;
    width: 120px !important;
    //color: #6095C0;
  }
}