.error-nav-logo {
    height: 100px;
    width: 100px;
    margin-left: 20px;
}

.error-body {
    width:100%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.error-message, .error-sub-message, .error-title {

    margin-top:10px;
    text-align: center;
}
.error-title {
    font-size:2.5em;
    color: #186d7f;
}

