.toggle_switch_wrapper {
  display: inline-block;
}

.toggle_switch {
  border: none;
  background: none;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 36px;
  height: 20px;
}

.toggle_switch:focus {
  outline: dotted 2px black;
  outline-offset: 2px;
}

.toggle_switch_text {
  display: inline-block;
  vertical-align: middle;
  margin-left: 1rem;
}

.toggle_switch_slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.1s;
  transition: 0.1s;
  border-radius: 18px;
}

.toggle_switch:disabled .toggle_switch_slider {
  cursor: not-allowed;
}

.toggle_switch_slider:before {
  position: absolute;
  content: '';
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background: #424242;
  transition: 0.1s;
  border-radius: 50%;
  box-shadow: 0 0 3px #424242;
}

.toggle_switch_checked .toggle_switch_slider {
  background-color: #0d8484;
}

.toggle_switch_checked:focus .toggle_switch_slider {
  box-shadow: 0 0 2px #efefef;
}

.toggle_switch_checked .toggle_switch_slider:before {
  transform: translateX(15px);
  background: #efefef;
}

.toggle_switch_slider.round {
  border-radius: 18px;
}

.toggle_switch_slider.round:before {
  border-radius: 50%;
}

.toggle_switch_text {
  font-weight: 500;
}

.active_label {
  font-weight: 600;
}
