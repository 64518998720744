.tooltip-wrapper {
  display: inline-block;
  position: relative;

  .tooltip {
    position: absolute;
    border-radius: 4px;
    left: 50%;
    transform: translateX(-50%);
    padding: 6px;
    color: white;
    background-color: #757575;

    font-size: 11px;
    font-family: sans-serif;
    line-height: 1;
    z-index: 100;
    white-space: nowrap;

    .tooltip.top {
      top: calc(30px * -1);
    }

    .tooltip.left {
      left: auto;
      right: calc(100% + 30px);
      top: 50%;
      transform: translateX(0) translateY(-50%);
    }

    .tooltip.right {
      left: calc(100% + 30px);
      top: 50%;
      transform: translateX(0) translateY(-50%);
    }

    .tooltip.bottom {
      bottom: calc(30px * -1);
    }
  }

  .tooltip.top {
    top: calc(30px * -1);
  }

  .tooltip.left {
    left: auto;
    right: calc(100% + 30px);
    top: 50%;
    transform: translateX(0) translateY(-50%);
  }

  .tooltip.right {
    left: calc(100% + 30px);
    top: 50%;
    transform: translateX(0) translateY(-50%);
  }

  .tooltip.bottom {
    bottom: calc(30px * -1);
  }
}