.patient-settings-popup {
  background-color: white;
  border: solid 2px #676767;
  border-radius: 4px;
  box-shadow: 0px 5px 5px -3px #ccc, 0px 8px 10px 1px #ccc, 0px 3px 14px 2px #ccc;
  width: 300px;
  min-height: 300px;
  padding: 0.5rem 1rem;
  z-index: 20;

  .patient-setting-switch {
    margin-bottom: 8px;
  }

  h3 {
    font-size: 1rem;
    font-family: Roboto;
    font-weight: 700;
    line-height: 1.75;
    margin: 1rem 0 0.5rem;
  }

  .patient-setting-name {
    text-align: right;
    margin: 0;
  }
}

.education-list-wrapper {
  position: relative;
  overflow: hidden;
  max-height: 210px;

  .fade-out-gradient {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40px;
    background: black;
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  }
}

.education-list-outer,
.education-list-inner {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

.education-list-date {
  color: #0d8484;
  font-weight: 700;
}

.education-list-inner {
  margin-bottom: 1rem;
}

.education-list-inner li {
  padding-left: 1em;
  text-indent: -1em;
}

.view-full-education-button-wrapper {
  margin: 0.25rem 0 1rem;
}

.view-full-education-button {
  display: block;
  width: 100%;
  text-align: center;
}

.no-education-history {
  font-style: italic;
}