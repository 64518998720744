.button_common {
  box-sizing: border-box;
  font-size: 1rem;
  font-style: normal;
  text-decoration: none;
  line-height: 1;
  cursor: pointer;
}

.button_common * {
  box-sizing: border-box;
}

.button {
  padding: 0.75rem 1rem;
  border: 1px solid #0d8484;
  background-color: #0d8484;
  color: white;
}

.unstyled {
  border-radius: 0;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  text-transform: none;
  overflow: visible;
  font-family: inherit;
}

.unstyled:focus,
.button:focus {
  outline: 2px dotted #424242;
  outline-offset: 2px;
}

.rounded {
  border-radius: 4px;
}

.rounder {
  border-radius: 8px;
}

.accent {
  border: 1px solid #a03123;
  background-color: #dd372f;
}

.neutral {
  border: 1px solid #424242;
  background-color: #ffffff;
  color: #424242;
}

.warn {
  border: 1px solid #ffb71b;
  background-color: #ffb71b;
  color: #424242;
}

.outline {
  background: none;
  border: 1px solid #0d8484;
  color: #0d8484;
  font-weight: 700;
}

.borderless {
  border: none;
}

.small {
  padding: 0.35rem 0.5rem;
  font-size: 0.9rem;
  line-height: 1;
}

.unstyled.accent,
.unstyled.neutral {
  color: white;
}

.outline.accent {
  color: #a03123;
  border-color: #a03123;
}

.outline.neutral {
  color: #676767;
  border-color: #676767;
}

.icon_wrapper {
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  margin-right: 0.5rem;
}

.icon_wrapper.icon_only {
  margin-right: 0;
}

.icon_wrapper svg {
  width: 20px;
  height: 20px;
  fill: white;
}

.small .icon_wrapper {
  width: 16px;
  height: 16px;
}

.small .icon_wrapper svg {
  width: 16px;
  height: 16px;
}

.outline .icon_wrapper svg {
  fill: #0d8484;
}

.outline.accent .icon_wrapper svg {
  fill: #a03123;
}

.warn .icon_wrapper svg,
.outline.neutral .icon_wrapper svg {
  fill: #424242;
}

.outline.warn .icon_wrapper svg {
  fill: #ffb71b;
}

.unstyled:disabled,
.button:disabled {
  opacity: 0.5;
  background-color: #676767;
  border-color: #676767;
  cursor: not-allowed;
}

.outline:disabled {
  background-color: transparent;
}
