li.MuiButtonBase-root.MuiListItem-root.conceptListItem.MuiListItem-button {
  border-collapse: collapse;
  cursor: default;
  display: table;
  width: 100%;
  table-layout: fixed;
  margin-bottom: 8px;
  box-sizing: border-box;

  &.selectedListItem {
    outline: 2px dotted #424242;
  }

  .conceptListItem-icon,
  .conceptListItem-title {
    padding: 0 12px;
    display: table-cell;
    width: 35px;
    vertical-align: top;
  }

  .conceptListItem-title {
    width: inherit;
    word-wrap: break-word;
  }

  .iconWrapper {
    min-width: 0;
    margin-right: 10px;
    margin-top: 4px;
  }

  .label {
    font-weight: 700;
  }
}
