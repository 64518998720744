.edit {  
  .edit-header {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  .edit-header-title {
    display: flex;
    justify-content: space-between;

    .edit-header-text {
      padding-top: 6px;
    }
  }

  .edit-header-controls {
    display: block;
    text-align: right;
    margin-top: 10px;

    .edit-save {
      margin-left: 10px;
    }
    .edit-undo {
      background-color: #ffffff;
      color: #676767;
    }

    .disabled {
      cursor: default;
      opacity: 0.5;
    }
  }
  @media screen and (max-width: 720px) {
    .edit-header-text {
      width: 85%;
    }
  }
}
