.chip {
    .MuiChip-root {
        background-color: #d8d8d8;
        margin-right: 5px;
    }

    .MuiChip-label {
        font-size:14px;
        color: #424242;
    }
}

