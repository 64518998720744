.footer {
  width: 100%;
  height: 50px;
  border-top: 2px solid rgba(0, 0, 0, 0.22);

  .footer-links {
    display: block;
    text-align: right;
    // float: right;

    .footer-link {
      padding-right: 7.5px;
      padding-left: 7.5px;
    }

    .footer-copyright-text {
      font-size: small;
      margin-right: 7.5px;
      display: inline-block;
      margin-bottom: 5px;
    }
  }

  .footer-links button:focus {
    outline: 2px dotted #424242;
  }

  .footer-disclaimer {
    float: right;
    width: 330px;
    font-size: x-small;
    color: #676767;
  }
}
