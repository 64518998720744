.preview-modal {
  .HwVideoTag {
    margin-right: 50px;
    max-width: 100%;
  }

  .HwVideoThumbnailContainer {
    display: none;
  }

  .HwContentTitle,
  .HwCustom,
  .HwContentHeader {
    display: none;
  }

  .HwDisclaimer,
  .HwCopyright {
    padding-left: 0px;
  }

  .preview-loading-container {
    text-align: center;
    margin-top: 25px;
  }

  .HwContentWrapper {
    padding-right: 15px;
  }

  /* CKE EDITOR STYLES */
  .cke_chrome {
    border-top: 2px solid #0d8484;
  }

  .cke_top {
    background-color: #d9eee5;
  }

  .cke_bottom {
    border-top: 2px solid #0d8484;
    background-color: #d9eee5;
  }

  @media screen and (max-width: 1020px) {
    .HwVideoTag {
      max-width: 60%;
      margin-right: 10px;
    }
  }
}
