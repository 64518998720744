.header-outer {
  position: relative;
  top: 0;
  width: 100%;
  background-color: white;
  z-index: 1000;
}

.header-nav {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-left: 25px;
  margin-right: 10px;
  margin-top: 10px;

  .nav-logo {
    margin-right: 20px;

    .main-logo:focus {
      outline: 2px dotted #424242;
    }
  }

  .nav-search {
    margin-right: 20px;
    flex-grow: 2;
  }

  .nav-search-language {
    display: inline-block;
    vertical-align: bottom;
    width: 70px;
  }

  .nav-search-bar {
    display: inline-block;
    width: calc(100% - 70px);
    padding-left: 12px;
  }

  .nav-icon {
    display: inline-block;
    width: 48px;

    .MuiSvgIcon-root,
    .nav-icon-feedback {
      color: #727272;
    }

    .feedback-button {
      width: 48px;
      height: 48px;
      padding-top: 16px;
    }
  }

  .main-logo {
    width: 110px;

    img {
      width: 90px;
      height: auto;
      border: none;
    }
  }

  .nav-icon-feedback {
    width: 22px;
    height: 22px;
    border: none;
  }
}

.header-cart {
  margin-left: 17.5px;
}

@media (max-width: 720px) {
  .nav-search {
    order: 1;
    flex-basis: 100%;
  }
}
