.list-item-root:focus {
  outline: 2px dotted #424242;
}

.list-item {
  list-style: none;
  display: flex;
  cursor: pointer;
  padding-bottom: 5px;
  
  &:hover {
    background-color: #f5f5f5;
    .list-item-content-title {
      text-decoration: underline;
    } 
  }
  
  .list-item-control {
    display: flex;
    align-items: center;
  }
  .list-item-content {
    flex-grow: 2;
    flex-shrink: 1;
    flex-wrap: wrap;

    .content-assigned-indicator {
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
    }

    .list-item-content-subtitle {
      display: inline-block;
    }

    .content-edited-chip {
      background-color: #676767;
      margin-left: 10px;
      color: white;
    }
  }
  .list-item-language-selector {
    margin-right: 10px;
    width: 48px;
  }
}

.list-item-cart {
  .content-title {
    padding-top: 5px;
  }
}

@media (max-width: 720px) {
  .list-item {
    .list-item-control {
      .MuiListItemIcon-root {
        min-width: 0px;
      }
    }
  }
}
